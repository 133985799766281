<template>
  <articleHead :info="{ title: '实验室' }" :isArticle="false" />
  <div ref="img_box" class="img_box">
    <div v-if="!loading" class="container" id="container">
      <template v-for="(item, index) in columnArr" :key="'cloumn_' + item">
        <div class="column" :style="{ '--column': columnArr[index] }">
          <template
            v-for="(hItem, hIndex) in hexagonArr[index]"
            :key="'hexagon_' + hIndex"
          >
            <div
              :ref="'item_' + index + '_' + hIndex"
              @click="
                proIndexArr[index].includes(hIndex) &&
                projectList[proIndexObj['i' + index + '_' + hIndex]]
                  ? goProDetail($event, index, hIndex)
                  : ''
              "
              class="hexagon"
              :style="{
                '--index': hIndex + 1,
                '--icon':
                  proIndexArr[index].includes(hIndex) &&
                  projectList[proIndexObj['i' + index + '_' + hIndex]]
                    ? ''
                    : iconArr[index][hIndex],
                'background-image':
                  proIndexArr[index].includes(hIndex) &&
                  projectList[proIndexObj['i' + index + '_' + hIndex]]
                    ? 'url(' +
                      projectList[proIndexObj['i' + index + '_' + hIndex]].img +
                      ')'
                    : '',
              }"
            ></div>
            <!-- <div v-else  :ref="'item_'+index+'_'+hIndex" class="hexagon" :style="{'--index':hIndex+1,'--icon':iconArr[index][hIndex]}" ></div> -->
            <!-- v-if="proIndexArr[index].includes(hIndex)&&projectList[proIndexObj['i'+index+'_'+hIndex]]"  -->
          </template>
        </div>
      </template>
    </div>
    <div v-if="showFrame" class="iframe-box">
      <iframe :src="projectUrl" width="100%" height="100%"></iframe>
      <i @click="closeFrame()" class="frame-close iconfont icon-guanbi"></i>
    </div>
  </div>
</template>
<script>
import { usePost } from "@/hooks/index";
import { urlForGetProjectList } from "@/api/url";
import articleHead from "@/components/articleHead/articleHead.vue";
const post = usePost();
export default {
  name: "laboratory",
  components: {
    articleHead,
  },

  data() {
    return {
      page: 1,
      total: 1,
      iconArr: [
        ['"⌨️"', '"💡"', '"🕶️"'],
        ['"👽"', '"🌌"', '"🎧"', '"🌒"'],
        ['"❤️"', '"🎙️"', '"📸"', '"🕰️"', '"🚀"'],
        ['"🕹️"', '"👾"', '"✨"', '"🌴"'],
        ['"🕶️"', '"⚙️"', '"🍒"'],
      ],
      columnArr: [2, 3, 4, 5, 6],
      hexagonArr: [3, 4, 5, 4, 3],
      proIndexArr: [
        [0, 2],
        [0, 2],
        [1, 3],
        [0, 2],
        [0, 2],
      ],
      proIndexObj: {
        i0_0: 0,
        i0_2: 1,
        i1_0: 2,
        i1_2: 3,
        i2_1: 4,
        i2_3: 5,
        i3_0: 6,
        i3_2: 7,
        i4_0: 8,
        i4_2: 9,
      },
      size: 0,
      viewIndex: 0,
      showFrame: false,
      projectUrl: "",
      loading: true,
      projectList: [],
    };
  },
  mounted() {
    this.getProList();
  },
  methods: {
    init() {
      console.log("init");
      const container = document.getElementById("container");
      const hexagons = container.querySelectorAll(".hexagon");
      const hexagonElements = new Array(...hexagons);
      let ripple = (target) => {
        if (container.classList.contains("show-ripple")) {
          return;
        }
        console.log("进入ripple");
        const targetRect = target.getBoundingClientRect();
        const data = hexagonElements
          .map((element) => {
            const rect = element.getBoundingClientRect();
            const centerX = rect.x + rect.width / 2;
            const centerY = rect.y + rect.height / 2;
            const distance = Math.round(
              Math.sqrt(
                Math.pow(rect.x - targetRect.x, 2) +
                  Math.pow(rect.y - targetRect.y, 2)
              )
            );
            return {
              element,
              rect,
              centerX,
              centerY,
              distance,
            };
          })
          .sort((a, b) =>
            a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0
          );
        const [max] = data.slice(-1);
        data.forEach((item) =>
          item.element.style.setProperty(
            "--ripple-factor",
            `${(item.distance * 100) / max.distance}`
          )
        );
        const cleanUp = () => {
          requestAnimationFrame(() => {
            container.classList.remove("show-ripple");
            data.forEach((item) =>
              item.element.style.removeProperty("--ripple-factor")
            );
            max.element.removeEventListener("animationend", cleanUp);
          });
        };
        max.element.addEventListener("animationend", cleanUp);
        container.classList.toggle("show-ripple");
      };
      if (this.total > 1 && this.page !== this.total) {
        this.iconArr[3][3] = '"NEXT"';
      }
      hexagons.forEach((hexagon, index) => {
        hexagon.addEventListener("click", () => {
          if (index === 15 && this.iconArr[3][3] == '"NEXT"') {
            this.page += 1;
            this.getProList(this.page, false);
          }
          if (index === 6 && this.iconArr[1][3] == '"PREV"') {
            this.page -= 1;
            this.getProList(this.page, false);
          }

          if (this.page === this.total && this.page !== 1) {
            this.iconArr[3][3] = '"🌴"';
          }
          if (this.page === 1) {
            this.iconArr[1][3] = '"🌒"';
          } else {
            this.iconArr[1][3] = '"PREV"';
          }
          if (this.page < this.total) {
            this.iconArr[3][3] = '"NEXT"';
          }
          ripple(hexagon, hexagons);
          
        });
      });
      $(".hexagon").on("mouseenter mouseleave", function (event) {
        if (event.type === "mouseenter") {
          $(".hexagon:not(:hover)").css({ transform: "scale(0.9)" });
          $(".hexagon:hover").css({ transform: "scale(1.4)" });
        } else {
          $(".hexagon").css({ transform: "scale(1)" });
        }
      });
      // demo
      setTimeout(() => {
        ripple(hexagonElements[0], hexagons);
      }, 300);
    },
    getProList(page = 1, isInit = true) {
      post({
        url: urlForGetProjectList,
        data: {
          page,
        },
      })
        .then((res) => {
          this.projectList = res.data || [];
          this.page = res.page;
          this.total = res.total;
          console.log(res);
          this.loading = false;
          if (isInit) {
            this.$nextTick(() => {
              this.init();
            });
          }
        })
        .catch((err) => {
          this.projectList = [];
          this.loading = false;
          if (isInit) {
            this.$nextTick(() => {
              this.init();
            });
          }
        });
    },
    goProDetail(e, index, hIndex) {
      const name =
        this.projectList[this.proIndexObj["i" + index + "_" + hIndex]].url;
      this.projectUrl = "/project/" + name + "/index.html";
      console.log('路径',this.projectUrl)
      this.viewIndex = index + "_" + hIndex;
      if (!document.startViewTransition) {
        this.showFrame = true;
        return;
      }
      e.target.style.viewTransitionName = "full-embed";
      document.startViewTransition(() => {
        e.target.style.viewTransitionName = "";
        this.showFrame = true;
      });
    },
    closeFrame() {
      if (!document.startViewTransition) {
        this.showFrame = false;
        return;
      }
      this.$refs["item_" + this.viewIndex].viewTransitionName = "full-embed";
      document.startViewTransition(() => {
        this.$refs["item_" + this.viewIndex].viewTransitionName = "";
        this.showFrame = false;
      });
      this.projectUrl = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
::view-transition-old(full-embed) {
  display: none;
}
.iframe-box {
  view-transition-name: full-embed;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.frame-close {
  position: fixed;
  top: 5px;
  right: 5px;
  font-size: 20px;
  width: 30px;
  height: 30px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.img_box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: calc(100% - 80px);
  // background: var(--bg);
  // &:before {
  //       content: "";
  //       position: absolute;
  //       inset: 0;
  //       pointer-events: none;
  //       background: radial-gradient(at center, transparent 80%, black), linear-gradient(to top, rgba(0, 0, 0, 0.3) 70%, transparent);
  //       opacity: 0;
  //       filter: blur(0.5rem);
  //       transition: opacity 0.6s ease-in-out, filter 0.6s ease-in-out;
  //   }
}
// .img_box {
//   width: 100%;
//   // min-height: 1000px;
//   padding: 20px;
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
//   align-content: flex-start;
//   .img_cover {
//     width: 30%;
//     height: 132px;
//     margin: 10px;
//     display: flex;
//     flex-direction: column;
//     background: var(--main-bg);
//     border-radius: 6px;
//     box-shadow: 0 8px 16px rgb(0 0 0 / 30%);
//     overflow: hidden;
//     cursor: pointer;
//     .image {
//       height: 100px;
//       .image-slot {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 100%;
//         height: 100%;
//         background: var(--el-fill-color-light);
//         color: var(--el-text-color-secondary);
//         font-size: 14px;
//       }
//       .dot {
//         animation: dot 2s infinite steps(3, start);
//         overflow: hidden;
//       }
//       &:before {
//         color: var(--whitebgtextcolor);
//         font-family: iconfont;
//         content: "\e6ff";
//         display: block;
//         font-size: 18px;
//         height: 30px;
//         left: calc(50% - 18px);
//         top: calc(50% - 18px);
//         line-height: 35px;
//         position: absolute;
//         text-align: center;
//         transform: scale(0);
//         opacity: 0;
//         transition: 0.25s;
//         width: 30px;
//         z-index: 1;
//       }
//       // &:hover:before {
//       //   transform: scale(2);
//       //   opacity: 1;
//       //   transition: 0.25s;
//       // }
//       &:after {
//         background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
//         content: "";
//         display: block;
//         height: 100%;
//         left: 0;
//         position: absolute;
//         top: 0;
//         width: 100%;
//         opacity: 0;
//         transition: 0.25s;
//         border-top-left-radius: 6px;
//         border-top-right-radius: 6px;
//       }
//       &:hover:after {
//         opacity: 1;
//         transition: 0.25s;
//       }
//     }
//     .clock-image {
//       height: 100px;
//       position: relative;
//       &:before {
//         color: var(--normaltextcolor);
//         font-family: iconfont;
//         content: "\e602";
//         display: block;
//         font-size: 35px;
//         left: calc(50% - 18px);
//         top: calc(50% - 18px);
//         line-height: 35px;
//         position: absolute;
//         text-align: center;
//         z-index: 1;
//       }
//       &:after {
//         background: var(--imagebg) none repeat scroll 0 0;
//         content: "";
//         display: block;
//         height: 100%;
//         left: 0;
//         position: absolute;
//         top: 0;
//         width: 100%;
//         border-top-left-radius: 6px;
//         border-top-right-radius: 6px;
//       }
//     }
//     .img_title {
//       width: 100%;
//       height: 32px;
//       text-align: center;
//       line-height: 32px;
//       overflow: hidden;
//       font-size: 14px;
//       color: var(--imagetext);
//     }
//   }
// }
</style>
