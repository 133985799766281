
import router from "../router";


router.beforeEach(async (to) => {
    // to and from are both route objects. must call `next`.
    // console.log('beforeEach', to)
    

})
router.afterEach(() => {
    
});