<template>
  <header class="box-header">
    <h1 class="">sprinkle</h1>
    <small>{{ longsign }}</small>
  </header>

  <div class="article_list">
    <el-skeleton :loading="toploading" animated>
      <div
        v-if="articleTop"
        :style="'background-image: url(' + articleTop.img + ');'"
        class="article_item article_top"
      >
        <router-link
          class="article_top_a"
          :to="{ path: '/detail/' + articleTop.id }"
        >
          <div class="article_top_mask">
            <h3>
              <span class="article_top_icon">置顶</span>{{ articleTop.title }}
            </h3>
            <div class="article_top_des">{{ articleTop.summary }}</div>
          </div>
        </router-link>
      </div>
    </el-skeleton>
    <el-skeleton :loading="listloading" :count="7" animated>
      <template v-for="(item, index) in articleList" :key="item.id">
        <!-- 基础样式 -->
        <div
          v-if="index == 0 || index == 3 || index == 5"
          class="article_item wow fadeIn"
        >
          <img :src="item.img" class="article_img" alt="" />
          <div class="article_info">
            <h2 class="article_title">
              <router-link :to="{ path: '/detail/' + item.id }">
                {{ item.title }}
              </router-link>
            </h2>
            <p class="article_des">{{ item.summary }}</p>
            <div class="line"></div>
            <div class="other_info">
              <li>
                <span class="icon_sapn"
                  ><i class="iconfont icon-user"></i
                ></span>
                <span>{{ item.nickname }}</span>
              </li>
              <li>
                <span class="icon_sapn"
                  ><i class="iconfont icon-time-circle"></i
                ></span>
                <span>{{ item.updata_at }}</span>
              </li>
              <li>
                <span class="icon_sapn"><i class="iconfont icon-eye"></i></span>
                <span>{{ item.article_view }}</span>
              </li>
            </div>
          </div>
        </div>
        <!-- 背景图样式 -->
        <div
          v-if="index == 2 || index == 4"
          class="article_item_bg"
          :style="'background-image:url(' + item.img + ')'"
        >
          <!-- <div class="item_bg_content"> -->
          <div class="article_info">
            <h2 class="article_title">
              <router-link :to="{ path: '/detail/' + item.id }">
                {{ item.title }}
              </router-link>
            </h2>
            <p class="article_des">{{ item.summary }}</p>
            <div class="line"></div>
            <div class="other_info">
              <li>
                <span class="icon_sapn"
                  ><i class="iconfont icon-user"></i
                ></span>
                <span>{{ item.nickname }}</span>
              </li>
              <li>
                <span class="icon_sapn"
                  ><i class="iconfont icon-time-circle"></i
                ></span>
                <span>{{ item.updata_at }}</span>
              </li>
              <li>
                <span class="icon_sapn"><i class="iconfont icon-eye"></i></span>
                <span>{{ item.article_view }}</span>
              </li>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!-- emoje样式 -->
        <div v-if="index == 1" class="article_item_emoji wow fadeIn">
          <div class="article_info">
            <h2 class="article_title">
              <div class="article_title_emoji">{{ getRandomEmoje() }}</div>
              <router-link :to="{ path: '/detail/' + item.id }">
                {{ item.title }}
              </router-link>
            </h2>
            <p class="article_des">{{ item.summary }}</p>
            <div class="line"></div>
            <div class="other_info">
              <li>
                <span class="icon_sapn"
                  ><i class="iconfont icon-user"></i
                ></span>
                <span>{{ item.nickname }}</span>
              </li>
              <li>
                <span class="icon_sapn"
                  ><i class="iconfont icon-time-circle"></i
                ></span>
                <span>{{ item.updata_at }}</span>
              </li>
              <li>
                <span class="icon_sapn"><i class="iconfont icon-eye"></i></span>
                <span>{{ item.article_view }}</span>
              </li>
            </div>
          </div>
        </div>
      </template>
    </el-skeleton>
  </div>
  <div class="pagenation_lox">
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="6"
      :pager-count="5"
      :total="articleTotal"
      @prev-click="currentChange"
      @next-click="currentChange"
      @current-change="currentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
// let timer = null;
import { usePost, useGet } from "@/hooks/index";
import { getRandomInt } from "@/hooks/randomNum";
import {
  urlForGetArticleList,
  urlForGetArticleTop,
  urlForGetLongSign,
} from "@/api/url";
import { timeformatstande } from "@/hooks/timeformat";
const post = usePost(),
  get = useGet();
export default {
  name: "articleList",
  data() {
    return {
      emoji: ['💫','💯','🧐','🐱','🦁','🦄','🐼','🐚','🪸','🐌','🍀','🍬','🍰','🧋','⌛','🪐','🌟','🌠','🌌','🌈','🏡','🌃','🎃','✨','🪄','🎨','🎧','🎬','🚩'],
      articleTop: {},
      articleList: [],
      longsign: "",
      toploading: true,
      listloading: true,
      articleTotal: 0,
    };
  },

  mounted() {
    this.getArticleTop();
    this.getArticleList();
    // let _this = this;
    // timer = setInterval(() => {
    //   _this.getSign();
    // }, 15000);
  },
  unmounted() {
    // clearInterval(timer);
  },
  methods: {
    // 置顶文章
    getArticleTop: function () {
      let _this = this;
      post({
        url: urlForGetArticleTop,
        data: {},
      })
        .then((res) => {
          // console.log(res);
          let list = res.data[0];
          _this.articleTop = list;
          _this.toploading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRandomEmoje() {
      //获取emoji字符串中随机一个字符
      return this.emoji[getRandomInt(0, this.emoji.length - 1)];
    },
    //文章列表
    getArticleList: function (page = 1) {
      let _this = this;
      post({
        url: urlForGetArticleList,
        data: {
          page,
        },
        isProgress: true,
      })
        .then((res) => {
          // console.log(res);
          let list = res.data || [];
          list.map((item) => {
            item.mode = item.width > item.height ? "hov" : "ver";
            item.updata_at = timeformatstande(item.updata_at);
            item.article_view = item.article_view + "次浏览";
          });
          _this.articleList = list;
          _this.listloading = false;
          _this.articleTotal = res.total;
          _this.getSign();
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          // console.log(this)
          // _this.$refs.scrollbarRef.setScrollTop(0)
        })
        .catch((err) => {
          console.log(err);
          _this.getSign();
        });
    },
    //签名获取
    getSign: function () {
      get({
        url: urlForGetLongSign,
        data: "",
        method: "GET",
        beforeRequest: () => {},
        afterRequest: () => {},
      }).then((res) => {
        // console.log("res", res);
        if (res.code === 200) {
          // console.log(res)
          this.longsign = res.data[0].content;
        } else {
          this.longsign = "欲买桂花同载酒，只可惜故人...";
          return;
        }
      });
    },
    currentChange: function (e) {
      this.getArticleList(e);
    },
  },
};
</script>
<style lang="scss" scoped>
.box-header {
  background-color: var(--main-bg);
  padding: 20px;
  h1 {
    font-weight: 300;
    color: var(--titlecolor);
    margin: 0 !important;
  }
  small {
    letter-spacing: 2px;
    color: var(--normaltextcolor);
  }
}
.article_list {
  width: 100%;
  // min-height: 1000px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .article_item {
    width: 100%;
    height: 190px;
    margin-bottom: 20px;
    background-color: var(--main-bg);
    box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
    border-radius: 6px;
    overflow: hidden;
    // position: relative;
    display: flex;
    .article_img {
      width: 30%;
      height: 190px;
      object-fit: cover;
    }
    .article_info {
      width: 70%;
      height: 100%;
      padding: 30px 30px 15px;
      display: flex;
      flex-direction: column;
      .article_title {
        font-size: 22px;
        color: var(--titlecolor);
        margin: 0;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: normal;
        a {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .article_des {
        margin: 0;
        height: 60px;
        line-height: 2em;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--normaltextcolor);
      }
      .line {
        border-color: rgba(237, 241, 242, 0.6);
        border-bottom: 1px solid rgba(222, 229, 231, 0.45);
        width: 100%;
        height: 2px;
        margin: 13px 0;
        overflow: hidden;
        font-size: 0;
      }
      .other_info {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        color: var(--normaltextcolor);
        li {
          display: inline-block;
          padding: 0 10px 0 0px;
          .icon_sapn {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .article_item_bg {
    width: 100%;
    height: 260px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    display: flex;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;

    .article_info {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0px 30px 25px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background: var(--listimagecolorhover);
      .article_title {
        font-size: 22px;
        color: var(--listimagecolor);
        margin: 0;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: normal;
        a {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .article_des {
        margin: 0;
        height: 60px;
        line-height: 2em;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--listimagecolor);
      }
      .line {
        border-color: rgba(237, 241, 242, 0.6);
        border-bottom: 1px solid rgba(222, 229, 231, 0.45);
        width: 100%;
        height: 2px;
        margin: 15px 0;
        overflow: hidden;
        font-size: 0;
      }
      .other_info {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        color: var(--listimagecolor);
        li {
          display: inline-block;
          padding: 0 10px 0 0px;
          .icon_sapn {
            margin-right: 5px;
          }
        }
      }
      // }
    }
  }
  .article_item_emoji {
    width: 100%;
    height: 190px;
    margin-bottom: 20px;
    background-color: var(--main-bg);
    box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
    border-radius: 6px;
    overflow: hidden;
    // position: relative;
    display: flex;
    .article_info {
      width: 100%;
      height: 100%;
      padding: 30px 30px 25px;
      display: flex;
      flex-direction: column;
      .article_title {
        font-size: 22px;
        color: var(--titlecolor);
        margin: 0;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: normal;
        display: flex;
        align-items: center;
        a {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
        }
        .article_title_emoji{
          display: inline-block;
          margin-right: 5px;
        }
      }
      .article_des {
        margin: 0;
        height: 60px;
        line-height: 2em;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--normaltextcolor);
      }
      .line {
        border-color: rgba(237, 241, 242, 0.6);
        border-bottom: 1px solid rgba(222, 229, 231, 0.45);
        width: 100%;
        height: 2px;
        margin: 13px 0;
        overflow: hidden;
        font-size: 0;
      }
      .other_info {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        color: var(--normaltextcolor);
        li {
          display: inline-block;
          padding: 0 10px 0 0px;
          .icon_sapn {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .article_top {
    height: 250px;
    background-size: cover;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .article_top_mask {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: var(--whitebgtextcolor);
      background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5));
      padding: 30px;
      .article_top_icon {
        padding: 0.2em 0.6em 0.3em;
        display: inline;
        font-size: 13px;
        white-space: nowrap;
        border-radius: 0.25em;
        float: left;
        font-weight: 700;
        background-color: #f05050;
        color: var(--whitebgtextcolor);
        margin-right: 15px;
      }
      .article_top_des {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .article_top_a {
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
.pagenation_lox {
  width: 100%;
  padding: 10px 0 50px;
  .el-pagination {
    width: fit-content;
    margin: auto;
    :deep(.btn-prev) {
      background-color: var(--pagenationgb) !important;
    }
    :deep(.btn-next) {
      background-color: var(--pagenationgb) !important;
    }
    :deep(.number) {
      background-color: var(--pagenationgb);
    }
    :deep(.is-active) {
      background-color: var(--pagenationactive) !important;
    }
  }
}
a {
  text-decoration: none;
}
</style>
