import {
	ElMessageBox
} from "element-plus";
import {
	useGet
} from "@/hooks/index";
import {
	urlForGetVersion,
} from "@/api/url";
const get = useGet()
// 版本检查
export const versionCheck = async () => {
	get({
		url: urlForGetVersion
	}).then((res) => {
		console.log('版本信息ces', res)
		// return;
		if (res.code === 200) {
			console.log('版本信息', res.data)
			const version = JSON.parse(res.data).VUE_APP_VERSION||'';
			const oldVersion = window.localStorage.getItem('version');
			window.localStorage.setItem('version', Number(version))
			if (oldVersion) {
				if (Number(oldVersion) !== version) {
					ElMessageBox.confirm(`检测到有内容更新，请刷新以获取最新内容`, "更新提示", {
						confirmButtonText: "确认",
						type: "warning",
						showCancelButton: false,
						draggable: true
					}).then(async () => {
						window.location.reload();
					});
				}

			}
			
			//process.env.VUE_APP_VERSION  获取环境变量设置的值，判断是否与生成的版本信息一致
			// console.log('process.env.VUE_APP_VERSION', process.env.VUE_APP_VERSION, version)
			return;

		}

	});

};