const send = function (type, val) {
    if (type === 'Requst') {
        const data = JSON.stringify(val)
        new Image().src = `${process.env.VUE_APP_BASE_API}getSendData.gif?type=${type}&data=${data}`
    } else {
        if (navigator.sendBeacon) {
            if (type === 'PageStay') {
                val = val[0]
            }
            let formData = new FormData();
            formData.append('type', type)
            Object.keys(val).forEach(key => {
                console.log('append:', val)
                formData.append(key, val[key])
            })
            navigator.sendBeacon(`${process.env.VUE_APP_BASE_API}SendDataBeacon`, formData)
        } else {
            const data = JSON.stringify(val)
            new Image().src = `${process.env.VUE_APP_BASE_API}getSendData.gif?type=${type}&data=${data}`
        }
    }



}
export {
    send
}