<template>
  <router-view />
</template>
<script>
import { Snow } from "snowjs";
import wow from "wow.js";
import { usePost } from "@/hooks/index";
import { urlForpostIp } from "@/api/url";
import { debounce } from "./hooks/throttle";
const post = usePost();
export default {
  data() {
    return {};
  },
  mounted() {
    new wow({
      offset: 50,
    }).init();
    window.scrollTo(0, 0);
    let snow = new Snow("snowcavas", null, null, 150, 1, 5, 0.5, 1, true);
    // snow.start();
    post({ url: urlForpostIp,data:{origin:document.referrer} });

    window.addEventListener("resize", function () {
      debounce(function () {
        snow.start();
      }, 1000);
    });
  },
};
</script>
<style lang="scss">
::view-transition-new(root),
::view-transition-old(root) {
/* 关闭默认动画，否则影响自定义动画的执行 */
   animation: none !important;
}
.dark::view-transition-old(root) {
        z-index: 100 !important;
}
</style>
